<template>
  <div id="app">
    <div>
    <nav-bar />
    <aside-menu :menu="menu" />
    <router-view />
    </div>
    <footer-bar />
  </div>
</template>

<style>
textarea.jsoneditor-text, .ace-jsoneditor {
    min-height: 400px !important;
    min-width: 600px !important;
}
</style>

<script>
import NavBar from '@/components/NavBar'
import AsideMenu from '@/components/AsideMenu'
import FooterBar from '@/components/FooterBar'
import { mapState } from 'vuex'

export default {
  name: 'Home',
  components: {
    FooterBar,
    AsideMenu,
    NavBar
  },
  data () {
    return {
      orchestration: false,
      clearing: false
    }
  },
  async created () {
    await this.checkAvailable()
  },
  computed: {
    menu () {
      const menu = ['General',
        [
          {
            to: '/',
            icon: 'desktop-mac',
            label: 'Dashboard'
          }
        ]]
      if (this.checkRole('ROLE_ADMIN')) {
        menu.push('Security',
          [
            {
              to: '/auth/manager',
              icon: 'desktop-mac',
              label: 'Authentication Manager'
            }
          ])
      }
      if (this.clearing) {
        menu.push('Clearing',
          [
            {
              to: '/clearing',
              icon: 'archive-outline',
              label: 'Clearing Logs'
            }
          ])
      }
      if (this.checkRole('ROLE_ADMIN', 'ROLE_READER', 'ROLE_DESCRIPTION_READER')) {
        menu.push(
          'Self Description',
          [
            {
              to: '/selfdescription/resources',
              label: 'Resources',
              icon: 'file-document-multiple-outline'
            },
            {
              to: '/selfdescription/request',
              label: 'Request',
              icon: 'magnify'
            }
          ])
      }
      if (this.checkRole('ROLE_ADMIN', 'ROLE_READER', 'ROLE_ARTIFACT_PROVIDER_MANAGER', 'ROLE_ARTIFACT_PROVIDER_READER', 'ROLE_ARTIFACT_CONSUMER')) {
        menu.push(
          'Artifact Handling',
          [
            {
              to: '/artifacts/consumer',
              label: 'Consumer',
              icon: 'download'
            },
            {
              to: '/artifacts/provider',
              label: 'Provider',
              icon: 'upload'
            }
          ])
      }
      if (this.checkRole('ROLE_ADMIN', 'ROLE_ROUTE_MANAGER', 'ROLE_ROUTE_READER')) {
        menu.push(
          'Routes',
          [
            {
              to: '/routes',
              label: 'Routes',
              icon: 'routes'
            }
          ])
      }
      if (this.checkRole('ROLE_ADMIN', 'ROLE_PEF_MANAGER', 'ROLE_PEF_READER')) {
        menu.push(
          'Policy Enforcement Framework',
          [
            {
              to: '/pef/pap',
              label: 'Administration',
              icon: 'lock-open'
            }
          ])
      }
      if (this.checkRole('ROLE_ADMIN', 'ROLE_ORCHESTRATION_MANAGER', 'ROLE_ORCHESTRATION_READER') && this.orchestration) {
        menu.push(
          'Orchestration',
          [
            {
              to: '/orchestration/manager',
              label: 'Orchestration Manager',
              icon: 'cog-sync-outline'
            },
            {
              to: '/orchestration/workflow',
              label: 'Workflow Manager',
              icon: 'chart-sankey-variant'
            }
          ])
      }
      return menu
    },
    ...mapState(['api', 'roles'])
  },
  methods: {
    checkRole (...roles) {
      return this.roles.some(role => roles.includes(role))
    },
    async checkAvailable () {
      if (this.checkRole('ROLE_ADMIN', 'ROLE_ORCHESTRATION_MANAGER', 'ROLE_ORCHESTRATION_READER')) {
        try {
          await this.api.get('/orchestration')
          this.orchestration = true
        } catch (e) {
        }
      }
      if (this.checkRole('ROLE_ADMIN')) {
        try {
          await this.api.get(`/clearing?from=${new Date().getTime()}`)
          this.clearing = true
        } catch (e) {
        }
      }
    }
  },
  watch: {
    api () {
      this.checkAvailable()
    }
  }
}
</script>
