import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

function getRoles (token) {
  if (token) {
    const tokenData = JSON.parse(Buffer.from(token.split('.')[1], 'base64'))
    return tokenData.roles
  } else {
    return []
  }
}

export default new Vuex.Store({
  state: {
    /* User */
    username: localStorage.getItem('username'),
    roles: getRoles(localStorage.getItem('token')),
    token: localStorage.getItem('token'),

    /* NavBar */
    isNavBarVisible: true,

    /* FooterBar */
    isFooterBarVisible: true,

    /* Aside */
    isAsideVisible: true,
    isAsideMobileExpanded: false,

    /* API */
    api: axios.create({
      baseURL: 'api/',
      timeout: 60000,
      headers: {
        Authorization: (localStorage.getItem('token')) ? `Bearer ${localStorage.getItem('token')}` : undefined
      }
    })
  },
  actions: {
    async login ({ commit }, payload) {
      const formData = new FormData()
      formData.append('username', payload.username)
      formData.append('password', payload.password)
      try {
        const response = await this.state.api.post('/auth/signin', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        localStorage.setItem('username', payload.username)
        localStorage.setItem('token', response.data.token)

        commit('login', {
          username: payload.username,
          token: response.data.token
        })
      } catch (e) {
        console.log(e)
        throw new Error('Login failed')
      }
    }
  },
  mutations: {
    /* A fit-them-all commit */
    basic (state, payload) {
      state[payload.key] = payload.value
    },

    /* User */
    logout (state, payload = null) {
      localStorage.removeItem('username')
      localStorage.removeItem('token')
      state.username = undefined
      state.token = undefined
      state.api = axios.create({
        baseURL: 'api/',
        timeout: 60000
      })
    },
    login (state, payload) {
      if (!payload.username || !payload.token) {
        return
      }
      state.username = payload.username
      state.token = payload.token
      state.roles = getRoles(payload.token)
      state.api = axios.create({
        baseURL: 'api/',
        timeout: 60000,
        headers: {
          Authorization: `Bearer ${payload.token}`
        }
      })
    },

    /* Aside Mobile */
    asideMobileStateToggle (state, payload = null) {
      const htmlClassName = 'has-aside-mobile-expanded'

      let isShow

      if (payload !== null) {
        isShow = payload
      } else {
        isShow = !state.isAsideMobileExpanded
      }

      if (isShow) {
        document.documentElement.classList.add(htmlClassName)
      } else {
        document.documentElement.classList.remove(htmlClassName)
      }

      state.isAsideMobileExpanded = isShow
    }
  }
})
