<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar :has-right-visible="false">
      Dashboard
    </hero-bar>
    <section class="section is-main-section">
      <tiles>
        <card-widget
          class="tile is-child is-clickable"
          type="is-link"
          icon="file-document-multiple-outline"
          :number="numResources"
          label="Resources"
          @click.native="$router.push({name: 'selfdescriptionresources'})"
        />
        <card-widget
          class="tile is-child is-clickable"
          type="is-info"
          icon="axis-arrow"
          :number="numMessages"
          label="Exchanged messages"
          @click.native="$router.push({name: 'routes'})"
        />
        <card-widget
          class="tile is-child"
          type="is-success"
          icon="chart-timeline-variant"
          :number="uptime.count"
          :suffix="` ${uptime.label}${(uptime.count != 1) ? 's' : ''}`"
          label="Uptime"
        />
      </tiles>
      <tiles>
        <card-widget
          class="tile is-child is-clickable"
          type="is-primary"
          icon="file-check-outline"
          :number="numAgreements"
          label="Contract Agreements"
          @click.native="$router.push({name: 'pap'})"
        />
        <card-widget
          class="tile is-child is-clickable"
          type="is-info"
          icon="file-multiple-outline"
          :number="numArtifacts"
          label="Provided Artifacts"
          @click.native="$router.push({name: 'artifactprovider'})"
        />
        <card-widget
          class="tile is-child is-clickable"
          type="is-link"
          icon="chart-sankey-variant"
          :number="numWorkflows"
          label="Workflows"
          @click.native="$router.push({name: 'orchestrationworkflow'})"
        />
      </tiles>
      <card-component>
        <em>
          <p class="pb-2">Welcome to the User Interface for the TNO Core Container for participating in IDS-based Dataspaces.</p>
          <p class="pb-2">This UI provides the ability to interact with the API of the Core Container to manage its behavior. Several aspects are accessible in this UI:</p>
          <p class="pl-4"><router-link to="/selfdescription/resources"><strong>Self Description -> Resources</strong></router-link>: Show all resources published by this connector</p>
          <p class="pl-4 pb-2"><router-link to="/selfdescription/request"><strong>Self Description -> Request</strong></router-link>: Request the self description of an external connector</p>
          <p class="pl-4"><router-link to="/artifacts/consumer"><strong>Artifact Handling -> Consumer</strong></router-link>: Request artifacts and perform contract negotiation with an external connector</p>
          <p class="pl-4 pb-2"><router-link to="/artifacts/provider"><strong>Artifact Handling -> Provider</strong></router-link>: Manage artifacts published by the connector, by uploading new artifacts or removing existing ones</p>
          <p class="pl-4 pb-2"><router-link to="/routes"><strong>Routes -> Routes</strong></router-link>: View the active Apache Camel routes and their statistics, and manage routes</p>
          <p class="pl-4 pb-2"><router-link to="/pef/pap"><strong>Policy Enforcement Framework -> Administration</strong></router-link>: Interact with the Policy Administration Point of the embedded Policy Enforcement Framework to view agreed upon contracts, view published contract offers, or insert contracts or offers</p>
          <p class="pl-4"><router-link to="/orchestration/manager"><strong>Orchestration -> Orchestration Manager</strong></router-link>: Not available at this moment, will in the future provide a management interface for deploying data apps and containers around the core container</p>
          <p class="pl-4 pb-3"><router-link to="/orchestration/workflow"><strong>Orchestration -> Workflow Manager</strong></router-link>: Experimental interface for creating workflows (e.g. data pipelines) in the connector</p>
          <p><strong>Note:</strong> this is an early build of the User Interface and accompanying Core Container. A stable release of both components will be made open-source later this year (2022)</p>
        </em>
      </card-component>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import HeroBar from '@/components/HeroBar'
import Tiles from '@/components/Tiles'
import CardWidget from '@/components/CardWidget'
import CardComponent from '@/components/CardComponent'
import { mapState } from 'vuex'
export default {
  name: 'Home',
  components: {
    CardComponent,
    CardWidget,
    Tiles,
    HeroBar,
    TitleBar
  },
  data () {
    return {
      numResources: 0,
      numMessages: 0,
      uptime: {
        count: 0,
        label: 'second'
      },
      numAgreements: 0,
      numArtifacts: 0,
      numWorkflows: 0
    }
  },
  computed: {
    titleStack () {
      return ['Admin', 'Dashboard']
    },
    ...mapState(['api'])
  },
  async created () {
    await Promise.all([
      this.getCatalogs(),
      this.getRoutes(),
      this.getAgreements(),
      this.getWorkflows(),
      this.getArtifacts()
    ])
  },
  methods: {
    async getCatalogs () {
      try {
        const catalogs = await this.api.get('/resources')
        this.numResources = catalogs.data.map((catalog) => catalog['ids:offeredResource']?.length || 0).reduce((acc, a) => acc + a, 0)
      } catch (e) {
        console.log(e)
      }
    },
    async getArtifacts () {
      try {
        const artifacts = await this.api.get('/artifacts/provider')
        this.numArtifacts = artifacts.data.length || 0
      } catch (e) {
        console.log(e)
      }
    },
    async getRoutes () {
      try {
        const routes = await this.api.get('/routes')
        this.numMessages = routes.data.map((route) => route.stats.processed).reduce((acc, a) => acc + a, 0)

        const uptime = routes.data.map((route) => route.uptime).reduce((acc, a) => (a > acc) ? a : acc)
        if (uptime > 2 * 7 * 24 * 60 * 60 * 1000) {
          this.uptime = {
            count: Math.floor(uptime / (7 * 24 * 60 * 60 * 1000)),
            label: 'week'
          }
        } else if (uptime > 2 * 24 * 60 * 60 * 1000) {
          this.uptime = {
            count: Math.floor(uptime / (24 * 60 * 60 * 1000)),
            label: 'day'
          }
        } else if (uptime > 2 * 60 * 60 * 1000) {
          this.uptime = {
            count: Math.floor(uptime / (60 * 60 * 1000)),
            label: 'hour'
          }
        } else if (uptime > 2 * 60 * 1000) {
          this.uptime = {
            count: Math.floor(uptime / (60 * 1000)),
            label: 'minute'
          }
        } else {
          this.uptime = {
            count: Math.floor(uptime / 1000),
            label: 'second'
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getAgreements () {
      try {
        const agreements = await this.api.get('/pap/contracts')
        this.numAgreements = agreements.data.length || 0
      } catch (e) {
        console.log(e)
      }
    },
    async getWorkflows () {
      try {
        const workflows = await this.api.get('/workflow')
        this.numWorkflows = workflows.data.length || 0
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
