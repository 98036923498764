<template>
  <div>
    <title-bar :title-stack="titleStack" />
    <hero-bar>
      Login
    </hero-bar>
    <section class="section is-main-section">
      <card-component>
        <form @submit.prevent="loginSubmit">
          <b-field label="Username" horizontal>
            <b-input
              v-model="login.username"
              placeholder="Username"
              validation-message="Please provide an username"
              type="text"
              required
            />
          </b-field>
          <b-field label="Password" horizontal>
            <b-input
              v-model="login.password"
              placeholder="Password"
              validation-message="Please provide a password"
              type="password"
              required
            />
          </b-field>
          <hr />
          <b-field horizontal>
            <b-field grouped>
              <div class="control">
                <b-button native-type="submit" type="is-primary"
                  >Login</b-button
                >
              </div>
            </b-field>
          </b-field>
        </form>
      </card-component>
    </section>
  </div>
</template>

<script>
import TitleBar from '@/components/TitleBar'
import CardComponent from '@/components/CardComponent'
import HeroBar from '@/components/HeroBar'
import { mapState } from 'vuex'

export default {
  name: 'Login',
  components: {
    HeroBar,
    CardComponent,
    TitleBar
  },
  data () {
    return {
      login: {
        username: undefined,
        password: undefined
      }
    }
  },
  computed: {
    titleStack () {
      return ['Login']
    },
    ...mapState(['api'])
  },
  async created () {
  },
  beforeDestroy () {
  },
  methods: {
    async loginSubmit () {
      try {
        await this.$store.dispatch('login', {
          username: this.login.username,
          password: this.login.password
        })
        console.log('Login successful')
        if (this.$route.params.nextUrl !== undefined) {
          this.$router.push({ path: this.$route.params.nextUrl })
        } else {
          this.$router.push({ path: '/' })
        }
      } catch (e) {
        this.$buefy.snackbar.open({
          message: 'Login failed',
          type: 'is-danger',
          position: 'is-bottom',
          queue: false,
          duration: 20000
        })
        console.log('Login failed')
      }
    }
  }
}
</script>
