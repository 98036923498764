import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import store from '../store/index.js'

Vue.use(VueRouter)

const routes = [
  {
    meta: {
      title: 'Dashboard',
      requiresAuth: true
    },
    path: '/',
    name: 'home',
    component: Home,
    props: true
  },
  {
    meta: {
      title: 'Authentication Manager',
      requiresAuth: true
    },
    path: '/auth/manager',
    name: 'authmanager',
    component: () => import(/* webpackChunkName: "authmanager" */ '../views/AuthenticationManager.vue'),
    props: true
  },
  {
    meta: {
      title: 'Clearing Logs',
      requiresAuth: true
    },
    path: '/clearing',
    name: 'clearing',
    component: () => import(/* webpackChunkName: "authmanager" */ '../views/Clearing.vue'),
    props: true
  },
  {
    meta: {
      title: 'Login'
    },
    path: '/login',
    name: 'login',
    component: Login,
    props: true
  },
  {
    meta: {
      title: 'Artifact Consumer',
      requiresAuth: true
    },
    path: '/artifacts/consumer',
    name: 'artifactconsumer',
    component: () => import(/* webpackChunkName: "artifactconsumer" */ '../views/artifacts/ArtifactConsumer.vue'),
    props: true
  },
  {
    meta: {
      title: 'Artifact Provider',
      requiresAuth: true
    },
    path: '/artifacts/provider',
    name: 'artifactprovider',
    component: () => import(/* webpackChunkName: "artifactprovider" */ '../views/artifacts/ArtifactProvider.vue'),
    props: true
  },
  {
    meta: {
      title: 'Self Description Resources',
      requiresAuth: true
    },
    path: '/selfdescription/resources',
    name: 'selfdescriptionresources',
    component: () => import(/* webpackChunkName: "selfdescriptionresources" */ '../views/selfdescription/Resources.vue'),
    props: true
  },
  {
    meta: {
      title: 'Self Description Request',
      requiresAuth: true
    },
    path: '/selfdescription/request',
    name: 'selfdescriptionrequest',
    component: () => import(/* webpackChunkName: "selfdescriptionrequest" */ '../views/selfdescription/DescriptionRequest.vue'),
    props: true
  },
  {
    meta: {
      title: 'Route Management',
      requiresAuth: true
    },
    path: '/routes',
    name: 'routes',
    component: () => import(/* webpackChunkName: "routes" */ '../views/Routes.vue'),
    props: true
  },
  {
    meta: {
      title: 'Policy Administration Point',
      requiresAuth: true
    },
    path: '/pef/pap',
    name: 'pap',
    component: () => import(/* webpackChunkName: "pap" */ '../views/PolicyAdministrationPoint.vue'),
    props: true
  },
  {
    meta: {
      title: 'Orchestration Manager',
      requiresAuth: true
    },
    path: '/orchestration/manager',
    name: 'orchestrationmanager',
    component: () => import(/* webpackChunkName: "orchestrationmanager" */ '../views/orchestration/Manager.vue'),
    props: true
  },
  {
    meta: {
      title: 'Workflow Manager',
      requiresAuth: true
    },
    path: '/orchestration/workflow',
    name: 'orchestrationworkflow',
    component: () => import(/* webpackChunkName: "orchestrationworkflow" */ '../views/orchestration/Workflow.vue'),
    props: true
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})

function tokenValid (token) {
  const tokenData = JSON.parse(Buffer.from(token.split('.')[1], 'base64'))
  return tokenData.exp > (new Date().getTime() / 1000) - 120
}

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (localStorage.getItem('token') == null) {
      next({
        name: 'login',
        params: { nextUrl: to.fullPath }
      })
    } else {
      if (!tokenValid(localStorage.getItem('token'))) {
        store.commit('logout')
        next({
          name: 'login',
          params: { nextUrl: to.fullPath }
        })
      } else {
        next()
      }
    }
  } else {
    next()
  }
})

export default router
